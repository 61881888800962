import React, {useMemo} from 'react';
import './Home_Section_2.scss';
import YouTube from "react-youtube";
import {useMediaQuery} from "react-responsive";
import useScrollAnimation from '../../module/animation/useScrollAnimation';

const youtube_width_small = 370;
const youtube_height_small = 206;
const youtube_width_medium = 768;
const youtube_height_medium = 429;
const youtube_width_extra_large = 1190;
const youtube_height_extra_large = 671;

function Home_Section_2() {
    const isSmall = useMediaQuery({
        query: "(min-width:0px) and (max-width:834px)",
    });
    const isMedium = useMediaQuery({
        query: "(min-width:834px) and (max-width:1280px)",
    });
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });
    const youtubeWidth = useMemo(()=>{
        let width= youtube_width_small;
        if(isMedium){
            width= youtube_width_medium;
        }
        if(isExtraLarge){
            width= youtube_width_extra_large;
        }
        return width;
    },[isSmall, isMedium, isExtraLarge])
    const youtubeHeight = useMemo(()=>{
        let height= youtube_height_small;
        if(isMedium){
            height= youtube_height_medium;
        }
        if(isExtraLarge){
            height= youtube_height_extra_large;
        }
        return height;
    },[isSmall, isMedium, isExtraLarge])
    const youtubeOpts = useMemo(() => {
        return {
            width: youtubeWidth,
            height: youtubeHeight,
        }
    }, [youtubeWidth, youtubeHeight])
    return (
        <div className={'home-section-2'}>
            <YouTube className={'home-section-2-youtube-container'} videoId={'8irVEPJ1bsg'} opts={youtubeOpts}
                     style={{width: youtubeWidth, height: youtubeHeight}}/>
        </div>
    )
}

export default Home_Section_2;
