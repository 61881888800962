import React, { useState, useEffect } from "react";
import "./Support_Section_1.scss";
import "./../../styles/common.scss";
import { useMediaQuery } from "react-responsive";
import backgroundImageSmall from "./../../assets/image/support-section-1/section-1-background-small.svg";
import backgroundImageMedium from "./../../assets/image/support-section-1/section-1-background-medium.svg";
import backgroundImageLarge from "./../../assets/image/support-section-1/section-1-background-large.svg";
import toast from "react-hot-toast";
import AWS from "aws-sdk";
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;
const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;

function Support_Section_1() {
  const isSmall = useMediaQuery({ query: "(max-width: 834px)" });
  const isMedium = useMediaQuery({ query: "(max-width: 1280px)" });
  let backgroundImage;
  if (isSmall) {
    backgroundImage = backgroundImageSmall;
  } else if (isMedium) {
    backgroundImage = backgroundImageMedium;
  } else {
    backgroundImage = backgroundImageLarge;
  }
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    AWS.config.update({
      accessKeyId,
      secretAccessKey,
      region,
    });
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const emailSubmit = async () => {
    if (name && email && message) {
      try {
        const lambda = new AWS.Lambda({
          region: region,
        });
        const params = {
          FunctionName: "cs_sendMail_viaHomepage",
          Payload: JSON.stringify({
            from: companyEmail,
            to: companyEmail,
            subject: `An email from a ${name} has arrived on the Whisper website.`,
            body: `User's email address is ${email}. message: ${message}`,
          }),
        };
        lambda.invoke(params, function (error, data) {
          if (data.StatusCode !== 200) {
            toast.error(
              "There was an error sending the email. Please try again later."
            );
          } else {
            toast.success("Email sent successfully!");
            setName("");
            setEmail("");
            setMessage("");
          }
        });
      } catch (error) {
        toast.error(
          "There was an error sending the email. Please try again later."
        );
      }
    } else if (!name) {
      toast.error("Please enter name.");
    } else if (!email) {
      toast.error("Please enter email.");
    } else if (!message) {
      toast.error("Please enter message.");
    }
  };

  const inputData = [
    {
      title: "Full name",
      placeHolder: "Your full name",
      isLast: false,
      value: name,
      onChange: handleNameChange,
    },
    {
      title: "Email address",
      placeHolder: "Your email address",
      isLast: false,
      value: email,
      onChange: handleEmailChange,
    },
    {
      title: "Message",
      placeHolder: "Type your message",
      isLast: true,
      value: message,
      onChange: handleMessageChange,
    },
  ];
  return (
    <div className={"support-section-1 FLEX_CENTER"}>
      <div
        className={
          "support-section-1-container container FLEX_COLUMN_ALIGN_CENTER JUSTIFY_CENTER"
        }
      >
        <img
          className={"support-section-1-container-img"}
          src={backgroundImage}
          alt="section-1-background"
        />
        <div className="support-section-1-container-title">
          Ask us anything from Whisper. help you and make better serveice.
        </div>

        <div className="support-section-1-container-input">
          {inputData.map((el, index) => {
            return (
              <div
                className="support-section-1-container-input-wrapper"
                key={index}
              >
                <div className="support-section-1-container-input-wrapper-title">
                  {el.title}
                </div>
                <textarea
                  className={
                    el.isLast
                      ? "support-section-1-container-input-wrapper-input-last"
                      : "support-section-1-container-input-wrapper-input"
                  }
                  name={el.title.toLowerCase().replace(" ", "_")}
                  placeholder={el.placeHolder}
                  value={el.value}
                  onChange={el.onChange}
                />
              </div>
            );
          })}
        </div>

        <div
          className="support-section-1-container-btn FLEX_CENTER"
          onClick={() => emailSubmit()}
        >
          Subject
        </div>
      </div>
    </div>
  );
}

export default Support_Section_1;
