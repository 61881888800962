import React from 'react';
import './Home_Section_9.scss';
import Section_Title from "../../molcules/Section_Title";
import investor_icon from '../../assets/image/home-section-9/investor-icon.svg';
import developer_icon from '../../assets/image/home-section-9/developers-icon.svg';
import business_icon from '../../assets/image/home-section-9/business-icon.svg';
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import {Link} from "react-router-dom";

function Home_Section_9() {
    useScrollAnimation(['home-section-9']);

    return (
        <div className={'home-section-9 container'}>
            <Section_Title text={'Get Started'}/>
            <h3 className={'desc'}>
                New to Whisper? Start here
            </h3>
            <div className="box-container">
                <div className="box box-purple">
                    <div>
                        <img src={investor_icon} alt="investor"/>
                        <h2 className={'title'}>
                            INVESTORS
                        </h2>
                        <ul className={'info-container'}>
                            <li className={'info-wrapper'}>
                                <p className={'info'}>→ Buy Coin or Token</p>
                            </li>
                            <li>
                                <p className={'info'}>→ Explorer DApp and Use DApp</p>
                            </li>
                        </ul>
                    </div>
                    <Link to={'/download'} className={'button'}>
                        <h1 className="text">EXPLORER</h1>
                    </Link>
                </div>
                <div className="box box-orange">
                    <div>
                        <img src={developer_icon} alt="developer"/>
                        <h2 className={'title'}>
                            DEVELOPERS
                        </h2>
                        <ul className={'info-container'}>
                            <li className={'info-wrapper'}>
                                <p className={'info'}>→ Build anything</p>
                            </li>
                            <li>
                                <p className={'info'}>→ Easy to build and deploy</p>
                            </li>
                            <li>
                                <p className={'info'}>→ Custom fit for any business</p>
                            </li>
                        </ul>
                    </div>
                    <Link to={process.env.REACT_APP_EQHUB_URL} className={'button'}>
                        <h1 className="text">START BUILD</h1>
                    </Link>
                </div>
                <div className="box box-green">
                    <div>
                        <img src={business_icon} alt="business"/>
                        <h2 className={'title'}>
                            BUSINESS
                        </h2>
                        <ul className={'info-container'}>
                            <li className={'info-wrapper'}>
                                <p className={'info'}>→ Trusted By Global Industry Leaders</p>
                            </li>
                            <li>
                                <p className={'info'}>→ Various Whisper Use Cases for Enterprise</p>
                            </li>
                        </ul>
                    </div>
                    <Link to={'/support'} className={'button'}>
                        <h1 className="text">Contact Us</h1>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home_Section_9;
